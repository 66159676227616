// POSITION
.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.fixed {
  position: fixed;
}

.sticky {
  position: sticky;
}

.translate-middle-x {
  transform: translateX(-50%);
}

// ALIGNMENT
.left-0 {
  left: 0;
}

.left-50 {
  left: 50%;
}

.right-0 {
  right: 0;
}
.right-1 {
  right: 0.25rem;
}
.right-2 {
  right: 0.5rem;
}
.right-3 {
  right: 1rem;
}
.top-0 {
  top: 0;
}
.top-1 {
  top: 0.25rem;
}
.top-2 {
  top: 0.5rem;
}
.top-3 {
  top: 1rem;
}
.bottom-0 {
  bottom: 0;
}
.bottom-1 {
  bottom: 0.25rem;
}
.bottom-2 {
  bottom: 0.5rem;
}
.bottom-3 {
  bottom: 1rem;
}

// OVERFLOW
.overflow-hidden {
  overflow: hidden;
}

.overflow-auto {
  overflow: auto;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

// Z-INDEX
.z-index-1 {
  z-index: 1;
}

.z-index-2 {
  z-index: 2;
}

.z-index-3 {
  z-index: 3;
}

.z-index-4 {
  z-index: 4;
}

.z-index-5 {
  z-index: 5;
}
