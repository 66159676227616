// TEXT
.color-primary {
  color: var(--primary);
}

.color-primary-900 {
  color: var(--color-primary-900);
}

.color-green {
  color: #10ba73;
}

.color-green-400 {
  color: var(--color-green-400);
}

.color-dark {
  color: var(--dark);
}

.color-light {
  color: var(--light);
}

.color-gray-1 {
  color: var(--color-gray-1);
}

.color-gray-3 {
  color: var(--color-gray-3);
}

.color-orange {
  color: var(--color-orange);
}

.color-red {
  color: var(--color-red);
}

.color-turquoise {
  color: var(--color-turquoise);
}

// BACKGROUND
.bg-primary-gradient {
  color: var(--primary-contrast);
  background-color: var(--background-primary-gradient);
}

.bg-primary {
  background-color: var(--primary);
}

.bg-primary-100 {
  background-color: var(--color-primary-100);
}

.bg-light {
  background-color: var(--light);
}

.bg-orange-200 {
  background-color: var(--color-orange-200);
}

.bg-orange-500 {
  background-color: var(--color-orange-500);
}

.bg-orange {
  background-color: var(--color-orange);
}

.bg-orange-dark {
  background-color: var(--color-orange-900);
}

.bg-green {
  background-color: var(--color-green);
}

.bg-green-50 {
  background-color: var(--color-green-50);
}

.bg-green-400 {
  background-color: var(--color-green-400);
}

.bg-purple {
  background-color: var(--color-purple);
}

.bg-purple-800 {
  background-color: var(--color-purple-800);
}

.bg-turquoise {
  background-color: var(--color-turquoise);
}

.bg-turquoise-50 {
  background-color: var(--color-turquoise-50);
}

.bg-light-gray-turquoise {
  background-color: var(--color-light-gray-turquoise);
}

.bg-dark-gray-turquoise {
  background-color: var(--color-dark-gray-turquoise);
}

.bg-blue {
  background-color: var(--color-blue);
}

.bg-gray-2 {
  background-color: var(--color-gray-2);
}

.bg-gray-5 {
  background-color: var(--color-gray-5);
}

.bg-transparent {
  background-color: transparent;
}

.bg-app {
  background-color: var(--app-background);
}

.bg-dark-200 {
  background-color: var(--color-dark-200);
}

.bg-dark-300 {
  background-color: var(--color-dark-300);
}

.bg-red-50 {
  background: var(--color-red-50);
}

// FILL
.fill-primary {
  fill: var(--primary);
}

.fill-dark {
  fill: var(--dark);
}

.fill-light {
  fill: var(--light);
}

.fill-gray-3 {
  fill: var(--color-gray-3);
}

.fill-turquoise {
  fill: var(--color-turquoise);
}

.fill-green {
  fill: var(--color-green);
}

.fill-dark-gray-turquoise {
  fill: var(--color-dark-gray-turquoise);
}

.fill-orange {
  fill: var(--color-orange);
}

.fill-orange-200 {
  fill: var(--color-orange-200);
}

// BORDER
.border-color-light {
  border-color: var(--light);
}

.border-color-primary {
  border-color: var(--primary);
}

.border-color-primary-100 {
  border-color: var(--color-primary-100);
}

.border-color-warning {
  border-color: var(--color-orange-500);
}

.border-color-gray-5 {
  border-color: var(--color-gray-5);
}

.border-color-green {
  border-color: var(--color-green);
}
